//variables

$color-danger: red;
$color-warning: darkorange;
$color-success: darkblue;
$color-orangered: OrangeRed;
$color-green: green;

// custom scss
.fv-plugins-message-container {
  span {
    color: red;
  }
}

.font-2 {
  font-size: 2.5rem;
}

.margin-badge {
  margin: 3px 5px 3px 0px;
}

.mr-20 {
  margin-right: 1.2rem;
}

.max-w-700 {
  max-width: 700px;
}

.fs-icon {
  &-size {
    font-size: 1.3rem;
  }

  &-opacity {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
}

.custom-link:hover {
  .fs-icon-opacity {
    opacity: 1;
  }
}

.ml {
  &-2 {
    margin-left: 2px;
  }

  &-5 {
    margin-left: 5px;
  }

  &-10 {
    margin-left: 0.75rem;
  }

  &-20 {
    margin-left: 1.2rem;
  }
}

.pl-10 {
  padding-left: 1rem;
}

.width-5 {
  width: 5%;
}

.width-8 {
  width: 8%;
}

.width-9 {
  width: 9%;
}

.width-12 {
  width: 12%;
}

.width-15 {
  width: 15%;
}

.width-18 {
  width: 18%;
}

.width-20 {
  width: 20%;
}

.width-27 {
  width: 27%;
}

.min-width-108 {
  padding-right: 5px;
}

.custom-width-107 {
  width: 107px;
}

.custom-width-59 {
  width: 59px;
}

.h-70 {
  height: 70%;
}

.hide-btn {
  opacity: 0;
}

.z-icon {
  font-size: 2rem;
  color: $primary;
}

.z-icon-save {
  font-size: 1.8rem;
  color: $primary;
}

.z-icon-remove {
  font-size: 2rem;
  color: red;
}

.z-icon-check {
  font-size: 2rem;
  color: green;
}

.timelog-w-30 {
  width: 26px;
}

.margin-left-33 {
  margin-left: 33px;
}

.rdrDefinedRangesWrapper {
  width: 100% !important;
}

// model css override
#kt_modal_add_task {
  .modal-header {
    padding: 1rem;
  }
}

// .rdtPicker{
//   width: 53% !important;
//   position: absolute;
//   inset: auto auto 0px 0px;
//   transform: translate(1px,-38px)!important;
// }

.DOB_picker .rdtMonths > table:first-child {
  display: none;
}
.edit_log_datepicker .rdtPicker {
  width: 53% !important;
  position: absolute;
  inset: auto auto 0px 0px;
  transform: translate(1px, -38px) !important;
}

.due_date_parent .rdtPicker {
  inset: auto !important;
  transform: translate(1px, 0px) !important;
}

.duedate .rdtPicker th {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: black;
  font-weight: 600;
}

.duedate .rdtPicker td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-weight: 400;
  color: black;
}

.rtd,
.rtd_datetime {
  width: 93% !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive,
td.rdtYear,
td.rdtMonth:hover {
  // background-color: #3F40EF !important;
  border-radius: 26px !important;
}

.rdtPicker td.rdtDay:hover {
  border-radius: 15px !important;
}

.date-range-picker-parent {
  position: relative;

  @media (max-width: 374px) {
    .rdrDateRangePickerWrapper {
      transform: translate(-40px, -10px) scale(0.9);
    }
  }
  .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 9;
  }
}
.timelog-daterange {
  @media (max-width: 820px) {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    .rdrDefinedRangesWrapper {
      display: flex;
      flex-direction: column;
      width: 50%;

      .rdrStaticRanges {
        display: grid;
        grid-template-columns: 50% 50%;
      }

      .rdrInputRanges {
        display: none;
      }
    }
  }
}

.position-unset {
  position: unset;
}

.btn-danger {
  background-color: rgb(221, 51, 51) !important;

  &:hover:not(.btn-active) {
    background-color: rgb(221, 51, 51) !important;
  }
}

.custom-toast.swal2-icon-success {
  background-color: #07bc0c !important;
}

.custom-toast.swal2-icon-error {
  background-color: #e74c3c !important;
}

.custom-toast.swal2-icon-warning {
  background-color: #f1c40f !important;
}

.custom-toast.swal2-icon-info {
  background-color: #3498db !important;
}

.custom-toast.swal2-icon-question {
  background-color: #3498db !important;
}

.custom-toast .swal2-title {
  color: white;
}

.custom-toast .swal2-close {
  color: white;
}

.custom-toast .swal2-html-container {
  color: white;
}

// timlog login so far today table

.dataTable {
  margin-bottom: auto;
}

.timelog-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px 0px;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
}

.color {
  &-danger {
    color: $color-danger;
  }

  &-warning {
    color: $color-warning;
  }

  &-success {
    color: $color-success;
  }

  &-green {
    color: $color-green;
  }

  &-orangered {
    color: $color-orangered;
  }
}

// .time-log-text-area-row {
//   width: 97%;
//   padding-left: 2.5rem;
// }
.min-width-70 {
  width: 70px;
}

.bg-login-page-color {
  background-color: $primary;
}

.date-range-picker-parent {
  .form-control {
    padding: 0.6rem 1rem;
  }
}

// Added by sedhal for change form-control global css
.form-control {
  padding: 0.6rem 1rem;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 4px;
}

.timeline {
  .timeline-content {
    overflow: visible;
  }

  .form-control {
    padding: 0.6rem 1rem;
  }

  .min-w- {
    &220 {
      min-width: 220px;
    }
  }
}

.dashboard_hr_parent {
  @media (max-width: 1451px) {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.dashboardhr {
  @media (max-width: 1451px) {
    margin-left: -26px !important;
    padding: 0% !important;
  }
}

.contactUs:hover:not(.disabled) {
  font-weight: 500;
  color: black !important;
  text-decoration: underline !important;
}

.profileEditAddLink:hover:not(.disabled) {
  color: #4099de !important;
  text-decoration: underline !important;
}

// change clobal form-control css
.react-datepicker-wrapper {
  input {
    background-color: white;
    border-radius: 4px;
    box-shadow: none;
    cursor: default;
    min-height: 38px;
    padding: 5px 10px;
    border: 1px solid #e4e6ef;
    width: 100%;
  }
}

.leave-statistics {
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  border-radius: 6px;
  background-color: aliceblue;
}

.timelog-edit-popup {
  .react-datepicker-wrapper {
    input {
      width: 180px;
    }
  }
}

.react-datepicker-task-add-edit-page {
  .react-datepicker-wrapper {
    input {
      max-width: 160px;
    }
  }
}

.memberLeave_col {
  @media (min-width: 768px) and (max-width: 992px) {
    width: 50% !important;
  }
}

.memberLeave_col {
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100% !important;
  }
}

.timelogdata {
  @media (min-width: 1400px) {
    .timelogRowStiky {
      position: sticky;
      top: 120px;
      z-index: 60;
    }
  }

  .flex-1 {
    width: 100%;
    flex: auto;
  }

  .form-control {
    height: 38px;
  }

  @media (max-width: 767px) {
    textarea {
      &.form-control {
        height: 50px;
      }
    }
  }

  .data {
    &save {
      display: block;
      margin-top: 4px;
      width: 30px;
      height: 30px;
      background-color: #5da283;
      border: 2px solid #5da283;
      border-radius: 100%;
      background-image: escape-svg($form-check-input-checked-bg-image);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 60% 60%;
      cursor: pointer;

      @media (max-width: 1400px) {
        margin-top: 32px;
      }

      @media (max-width: 767px) {
        margin-top: 28px;
      }

      &.saved {
        background-color: #5da283;
      }
    }

    &tasksave {
      width: 26px;
      height: 26px;
      background-size: unset;
      margin-top: 0;
      margin-right: 0.3rem;
    }

    &delete {
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 9;
      width: 26px;
      height: 26px;
      display: block;
      background-color: #e04f5f;
      border-radius: 100%;

      .z-icon {
        color: #fff;
        transform: rotate(45deg);
        display: block;
        font-size: 26px;
      }
    }

    &-delete-position {
      top: 2px;
      right: -8px;
    }

    &-save-position {
      position: absolute;
      top: 2px;
      right: 20px;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #d4dde5;
  }

  .addmore {
    i {
      font-size: 20px;
      margin-right: 5px;
    }

    &_row {
      position: absolute;
      left: -8px;
      top: calc(50% - 13px);
      z-index: 9;
      background: #ffffff;
      border-radius: 100%;

      .z-icon {
        font-size: 15px;
      }
    }
  }

  .addmore {
    @media (max-width: 990px) {
      i {
        font-size: 20px;
        margin-right: 5px;
      }

      // &_row {
      //   top: calc(50% - -101px);
      // }
    }
  }

  .addmore {
    &_row {
      @media (min-width: 768px) and (max-width: 992px) {
        top: calc(50% - 13px);
      }

      @media (min-width: 440px) and (max-width: 768px) {
        top: calc(50% - 13px);
      }

      @media (min-width: 320px) and (max-width: 439px) {
        top: calc(45% - 13px);
      }
    }
  }

  .form {
    &-check {
      &.form-check-solid {
        .form {
          &-check {
            &-input {
              //margin-top: 4px;
              width: 35px;
              height: 35px;
              background-color: transparent;
              border: 1px solid #e4e6ef;

              &:checked {
                background-color: #5da283;
                border-color: #5da283;
              }
            }
          }
        }
      }
    }
  }
}

.log {
  &date {
    width: 125px;
  }

  &total {
    width: 90px;
  }

  &billable {
    width: 120px;
  }

  &status {
    width: 60px;
  }

  &action {
    width: 55px;

    .form-check-input {
      border-radius: 100%;
      border: 1px solid #5da283;
      background-size: 60% 60%;
      cursor: pointer;

      &:checked {
        background-color: #5da283;
      }
    }

    @media (max-width: 1400px) {
      width: 60px;
      display: flex;
      flex-direction: row;
    }
  }
}

.border-danger__control {
  border-color: #f1416c !important;
}

.leave-sticky {
  @media (min-width: 1400px) {
    .leave-row {
      position: sticky;
      top: 150px;
      z-index: 60;
    }
  }
}

.project-task-forms {
  .date-range-picker {
    z-index: 9999;
  }

  .bi-plus-circle-fill,
  .bi-dash-circle-fill {
    cursor: pointer;
  }
}

.fontsize {
  &-normal {
    font-size: 1.1rem;
  }
}

.__react_component_tooltip {
  max-width: 310px;
}

.dark-green-row {
  background-color: forestgreen;
  color: black !important;

  .bi-info-circle-fill {
    color: black;
  }
}

.green-row {
  background-color: #62c462;
  color: black !important;

  .bi-info-circle-fill {
    color: black;
  }
}

.yellow-row {
  background-color: #f3dd77 !important;
  color: black !important;

  .bi-info-circle-fill {
    color: black;
  }
}

.red-row {
  background-color: #ee5f5b;
  color: black !important;

  .bi-info-circle-fill {
    color: black;
  }
}

.review-timelog-table {
  td {
    padding: 0.5rem 0.5rem !important;
    font-weight: normal;
    border-left: 1px dashed white;
  }

  th {
    padding: 0.5rem 0.5rem !important;
  }

  .bi-info-lg {
    color: black;
  }
}

.error-border {
  border-color: #e04f5f;
}

.daily-task-drawer {
  .drawer-scroll {
    overflow-y: scroll;
    height: 100vh;
    max-height: 90vh;
  }
}

.assign-by-me-filter-field.form-switch {
  .form-check-input {
    width: 6rem;
  }

  input[value=''].form-check-input::before {
    font-weight: 500 !important;
    color: #173748b3;
    content: 'No ';
    font-size: 15px;
    height: 38px;
    padding-top: 8px;
    padding-left: 43px;
    display: block;
    font-family: sans-serif;
    letter-spacing: 0px;
  }

  input[value='yes'].form-check-input::before {
    font-weight: 500 !important;
    color: white;
    content: 'Yes ';
    font-size: 15px;
    height: 38px;
    padding-top: 8px;
    padding-left: 10px;
    display: block;
    font-family: sans-serif;
    letter-spacing: 0px;
  }
}

.assign-by-me-filter-field.form-switch.form-check-solid {
  .form-check-input {
    height: 2.8rem;
    position: relative;
  }
}

.max-w-300px {
  max-width: 300px;
}

.width-200px {
  width: 200px;
}

.width-125px {
  width: 125px;
}

.width-160px {
  width: 160px;
}

.max-w-200px {
  max-width: 200px;
}

.width-110px {
  width: 110px;
}

.width-100px {
  width: 100px;
}

.width-105px {
  width: 105px;
}

.max-w-100px {
  max-width: 100px;
}

.width-90px {
  width: 90px;
}

.max-w-90px {
  max-width: 90px;
}

.width-70px {
  width: 70px;
}

.max-w-70px {
  max-width: 70px;
}

.word-break-word {
  word-break: break-word;
}

.custom-font-size {
  font-size: 1.2rem;
}

.extraClass {
  font-size: 13px !important;
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.tableTooltip {
  font-size: 12px !important;
  pointer-events: auto !important;
  max-height: 400px !important;
  overflow: auto !important;
  overflow-x: hidden !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
    max-height: 400px !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    cursor: default !important;
  }
}

.tooltip_header {
  border-bottom: 1px solid !important;
}

.tooltip_body {
  color: lightgray !important;
}

.cancelBtn {
  color: gray !important;
}

.custom-spinner {
  height: 1rem !important;
  width: 1rem !important;
}

.custom-time-picker {
  .react-time-picker__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    border: 1px solid #e4e6ef;
    border-radius: 4px;
    background-color: #ffffff;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .react-time-picker__inputGroup {
      input {
        // font-weight: 500;
        line-height: 1.5;
        outline: unset;
        color: #181c32;
      }

      select {
        font-weight: 500;
        line-height: 1.5;
        outline: unset;
        color: #181c32;
      }
    }
  }
}

.timlog-time-picker {
  .react-time-picker__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #e4e6ef;
    border-radius: 4px;
    background-color: #ffffff;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .react-time-picker__inputGroup {
      input {
        font-weight: 500;
        line-height: 1.5;
        outline: unset;
        color: #181c32;
      }

      select {
        font-weight: 500;
        line-height: 1.5;
        outline: unset;
        color: #181c32;
      }
    }
  }
}

.topbarClass {
  @media (max-width: 767px) {
    display: none;
  }
}

.table-data {
  @media (max-width: 767px) {
    thead {
      display: none;
    }

    tr {
      &:nth-child(odd) {
        background-color: #e2e3e4;
      }
    }

    td[data-title] {
      width: 100%;
      display: block;
      position: relative;
      padding-left: 110px !important;
      padding-right: 10px !important;

      &:before {
        content: attr(data-title);
        font-weight: 700;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        width: 100px;
      }
    }
  }
}

div[data-disabled='true'] {
  pointer-events: none;
  opacity: 0.5;
}

.bug-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 16px;
}

.bug-title-head-input {
  .bug-textarea__inner {
    display: block;
    padding: 5px 15px;
    padding-left: 0;
    line-height: 1.5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: none !important;
    width: 100%;
    font-size: 35px !important;
    color: $primary;
    background: transparent;
    margin: 0;
    outline: none;
    -webkit-box-shadow: none !important;
    font-style: inherit;
    font-weight: 600;
    border: transparent !important;
    resize: none;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;

    // Chrome, Firefox, Opera, Safari 10.1+
    &::placeholder {
      color: $primary;
      opacity: 0.5;
    }

    // Firefox
    &::-moz-placeholder {
      color: $primary;
      opacity: 0.5;
    }
  }
}

.bug-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.bug-form-item__content:after,
.bug-form-item__content:before {
  display: table;
  content: '';
}

.text-divider {
  display: flex;
  align-items: center;
  text-align: center;

  &::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid #eff2f5;
  }

  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #eff2f5;
  }

  &:not(:empty) {
    &::before {
      margin-right: 0.25em;
    }

    &::after {
      margin-left: 0.25em;
    }
  }
}

#kt_table_users {
  tbody {
    tr {
      td {
        .react-datepicker-wrapper {
          input {
            width: 60%;
          }
        }
      }
    }
  }
}

.people-tab {
  .people-card {
    background-color: #f1f1f5;
  }
}

.avatar {
  &-people {
    border: 2px solid #e5e4e2;
    border-radius: 50%;
    padding: 1px;
    background: #fff;
  }
}

.rounded-full {
  border-radius: 9999px;
}

.symbol {
  &-people {
    color: #3f4254 !important;
    background-color: #fff !important;
  }
}

.missing-date {
  &-ul {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &-li {
    font-size: 12px;
    display: inline-block;
    margin-right: 1rem;
  }
}

.timelogRowTask {
  box-shadow: 0 0 15px #b6b0b0;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 30px;

  .datadelete {
    top: -25px;
    right: -21px;
  }

  .data-save-position {
    top: -25px;
  }
}

.popover-body {
  background-color: #173748;
  border-radius: 4px;
  color: #ffffff;
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  border-top-color: #173748;
}

.table-responsive.overflow-visible {
  overflow-y: visible;
  overflow-x: visible;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  cursor: pointer;
}

.pr-1 {
  padding-right: 5px;
}

.toast-link {
  color: -webkit-link;
  text-decoration: underline;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: '\25B2';
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: '\25BC';
  display: block;
  position: relative;
  bottom: 15%;
}

.react-simple-image-viewer__modal {
  z-index: 9999 !important;
}

.remove-spin-btn::-webkit-inner-spin-button,
.remove-spin-btn::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.comment-list-container {
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.comment-scroll {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  right: 0;
  bottom: 2px;
  display: flex;
  position: sticky;
  top: 0;
  background-color: #173748;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  opacity: 0.3;
  transition: color 0.2s ease, background-color 0.2s ease;
  border-radius: 0.475rem;

  &:hover {
    opacity: 1;
    background-color: #4a7389;
  }

  &.bottom {
    bottom: 0;
    top: 2px;

    .svg-icon {
      transform: rotate(180deg);
    }
  }

  .svg-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  // height: 140px;
  overflow-y: scroll;
  padding-left: 0;

  position: absolute;
  z-index: 999;
  background: #fff;
  color: black;
  width: 272px;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.css-26l3qy-auto-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  // position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

.css-4ljt47-auto-MenuList {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.css-1n7v3ny-auto-option {
  background-color: #deebff;
  color: inherit;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  cursor: pointer;
}

.css-yt9ioa-auto-option {
  background-color: transparent;
  color: inherit;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  cursor: pointer;
}

.css-b62m3t-container-auto {
  position: relative;
}

.css-26l3qy-auto-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}

// start custom bg-color and text color

$color-progress: #2844e3;
$color-reopen: #d9237b;
$color-not-0: #f5f8fa;
$color-not-1: #fff8dd;
$color-rfr: #0e850ae6;
$color-fix: #47a6b9;
$color-close: #d94325;
$color-dark-blue: #181c32;
$color-charcol: #464b62;

.badge-progress {
  color: $white;
  background-color: $color-progress;
}

.badge-reopen {
  color: $white;
  background-color: $color-reopen;
}

.badge-not-0 {
  color: $color-charcol;
  background-color: $color-not-0;
}

.badge-not-1 {
  color: $color-dark-blue;
  background-color: $color-not-1;
}

.badge-rfr {
  color: $white;
  background-color: $color-rfr;
}

.badge-fix {
  color: $white;
  background-color: $color-fix;
}

.badge-close {
  color: $white;
  background-color: $color-close;
}

// end custom bg-color and text color

.timelog-tab {
  @media (max-width: 767px) {
    .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      font-size: 0.85em;
    }
  }
}

.card-header:has(.timelog-tab) {
  padding: 0 !important;
}

.fa-solid {
  position: absolute;
  top: 15px;
  right: 15px;
}

.eyeWrapper {
  position: relative;
}

.inline-loader {
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0.8;
  bottom: 50%;
}

.bug-description {
  display: flex;
  flex-direction: column;

  a {
    color: blue;
  }
}

.bug-description-footer {
  background: #f5f8fa;
  border: 1px solid #ccc;
  border-top: '0';
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.pivot-table {
  thead {
    display: none;
  }

  tr {
    &:nth-child(odd) {
      background-color: #e2e3e4;
    }
  }

  td[data-title] {
    width: 100%;
    display: block;
    position: relative;
    padding-left: 110px !important;
    padding-right: 10px !important;

    &:before {
      content: attr(data-title);
      font-weight: 700;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: 100px;
    }
  }
}

.leave-export-daterange {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;

  .rdrDefinedRangesWrapper {
    display: flex;
    flex-direction: column;

    .rdrStaticRanges {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    .rdrInputRanges {
      display: none;
    }
  }
}

.sweet-alert-width {
  max-width: 40rem;
  min-width: 25rem;
}

.quill-editor-wrapper.disabled-toolbar-container {
  .ql-toolbar.ql-snow {
    display: none;
  }

  .ql-container.ql-snow {
    border: 1px solid #ccc !important;
  }
}

.modal-open #root {
  overflow: hidden;
}

.text-link {
  color: #1197ce;
}

.bg-viridian {
  background-color: #198c62 !important;
  border-color: #198c62 !important;
}

.bg-orange {
  background-color: #faa33e !important;
  border-color: #faa33e !important;
}

.bg-reddish-orange {
  background-color: #fa6123 !important;
  border-color: #fa6123 !important;
}

.bg-strawberry {
  background-color: #ff2629 !important;
  border-color: #ff2629 !important;
}

.unassign-symbol {
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  border: 1px;
  border-style: dashed;
  border-color: #9fc0d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bug-textarea_wrapper:has(textarea:focus) {
  border: 1px solid #e4e6ef;
  border-radius: 4px;
}

.project_Activity_parent {
  @media (min-width: 320px) and (max-width: 767px) {
    width: 35% !important;
  }
}

.project_Activity_child {
  @media (min-width: 320px) and (max-width: 767px) {
    width: 60% !important;
  }
}

.project_activity_footer {
  @media (min-width: 425px) and (max-width: 776px) {
    width: 91% !important;
  }

  @media (min-width: 776px) and (max-width: 1270px) {
    padding-right: 6px !important;
  }

  @media (min-width: 1300px) and (max-width: 1921px) {
    padding-left: 5rem;
  }
}

.pro_activity_fields,
.pro_activity_btn {
  @media (min-width: 425px) and (max-width: 555px) {
    width: 50% !important;
  }
}

.pro_activity_fields {
  @media (min-width: 555px) and (max-width: 755px) {
    width: 33% !important;
  }
}

.alerts-border {
  border: 1px #ff0000 solid;

  animation: blink 1s;
  animation-iteration-count: 3;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.comment-info {
  .comment-edit {
    display: none;
  }

  &:hover {
    .comment-edit {
      display: flex;
    }
  }
}
